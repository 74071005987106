import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { getDatabase, ref, onValue, off } from "firebase/database";
import { initializeApp } from "firebase/app";
import { formatDistanceToNow } from "date-fns";
import '../styles/CustomInfoWindow.css';
import MapControlSheet from './MapControlSheet';
import greenSvgIcon from '../assets/green-icon.svg';
import orangeSvgIcon from '../assets/orange-icon.svg';
import redSvgIcon from '../assets/red-icon.svg';
import blackSvgIcon from '../assets/black-icon.svg';
import blueDotSvgIcon from '../assets/blue-dot.svg';
import greenStarSvgIcon from '../assets/green-icon-star.svg';
import orangeStarSvgIcon from '../assets/orange-icon-star.svg';
import redStarSvgIcon from '../assets/red-icon-star.svg';
import selectedPubSvgIcon from '../assets/SelectedPub.png';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import { useMediaQuery } from 'react-responsive';
import LazyBeerLogo from "../LazyBeerLogo";
import { getGuinnessRatingLabel } from '../utils/guinnessRating';
import locationIcon from '../assets/location.svg';
import { useTheme } from '../utils/ThemeContext';
import PintFilterChips from './PintFilterChips';
import { addToRecentPubs } from '../utils/recentPubsService';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

const center = [51.50, -0.1];

const LocationButton = () => {
  const map = useMap();

  const handleLocationClick = () => {
    map.locate().on("locationfound", function (e) {
      map.flyTo(e.latlng, 15);
    });
  };

  return (
    <button
      onClick={handleLocationClick}
      className="absolute z-[1000] bottom-24 right-4 bg-white p-2 rounded-lg shadow-lg hover:bg-gray-100"
      aria-label="Center map on your location"
    >
      <img src={locationIcon} alt="" className="w-6 h-6" />
    </button>
  );
};

const formatPrice = (price) => price.toFixed(2);

const CustomInfoWindow = ({ pub, count }) => {
  const findCheapestPint = (pints) => {
    return pints.reduce((cheapest, current) => {
      return current.price < cheapest.price ? current : cheapest;
    });
  };

  const cheapestPint = findCheapestPint(pub.pints);
  const otherPints = pub.pints.filter(pint => pint.beerType !== cheapestPint.beerType);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 mb-16 custom-info-window" role="dialog" aria-label={`Information for ${pub.pub}`}>
      <div className="p-4">
        <Link to={`/pub/${pub.id}`} className="text-xl text-green-600 hover:text-green-700 dark:text-green-400 dark:hover:text-green-500 underline font-bold mb-2 block">
          {pub.pub}
        </Link>
        <p className="text-gray-600 dark:text-gray-300 text-sm mb-1">Cheapest Pint:</p>
        <p className="text-xl font-bold text-gray-800 dark:text-gray-100 mb-4">
          {cheapestPint.beerType} | £{formatPrice(cheapestPint.price)}
        </p>

        {pub.guinnessRating && cheapestPint.beerType.toLowerCase() === 'guinness' && (
          <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
            Guinness Rating: {pub.guinnessRating}/10
          </p>
        )}

        <p className="text-gray-600 dark:text-gray-300 text-sm mb-1">
          Last Recorded: {formatDistanceToNow(new Date(pub.timestamp))} ago
        </p>
        <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
          Contributions: {count}
        </p>

        <div className="mt-4">
          <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 mb-2">Other Pints:</h3>
          {otherPints.length === 0 ? (
            <p className="text-sm text-gray-500 dark:text-gray-400 font-bold">None</p>
          ) : (
            <ul className="space-y-2">
              {otherPints.map((pint, index) => (
                <li key={index} className="text-gray-700 dark:text-gray-200">
                  <p className="text-sm">
                    {pint.beerType} · <strong className="text-gray-900 dark:text-white">£{formatPrice(pint.price)}</strong> - {formatDistanceToNow(new Date(pint.timestamp))} ago
                    {pint.guinnessRating && pint.beerType.toLowerCase() === 'guinness' && (
                      <span className="align-middle bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2">
                        {getGuinnessRatingLabel(pint.guinnessRating)}
                      </span>
                    )}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 mb-2">Other amenities:</h3>
          <ul className="text-gray-600 dark:text-gray-300">
            <li>🍺 Beer Garden: {pub.beerGarden ? 'Yes' : 'Not reported'}</li>
            <li>🎶 Live Music: {pub.liveMusic ? 'Yes' : 'Not reported'}</li>
            <li>⚽️ Live Sports: {pub.liveSports ? 'Yes' : 'Not reported'}</li>
            <li className={pub.happyHour ? 'text-green-600 dark:text-green-400' : ''}>
              🍻 Happy Hour: {pub.happyHour ? 'Yes' : 'Not reported'}
            </li>
          </ul>
        </div>

        <Link to={`/pub/${pub.id}`} className="text-green-600 hover:text-green-700 dark:text-green-400 dark:hover:text-green-500 text-lg font-semibold underline mt-4 block">
          View Pub Details →
        </Link>
      </div>
    </div>
  );
};

const BottomSheet = ({ pub, count, isOpen, onClose }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const sheetClassName = `
    fixed bg-white dark:bg-gray-800 shadow-lg z-[1002] overflow-y-auto transition-all duration-300 ease-in-out
    ${isDesktop
      ? 'top-0 right-0 h-full w-1/3 transform'
      : 'bottom-0 left-0 right-0 max-h-[80vh] rounded-t-2xl transform'}
    ${isOpen
      ? 'translate-x-0 translate-y-0'
      : isDesktop ? 'translate-x-full' : 'translate-y-full'}
  `;

  const overlayClassName = `
    fixed inset-0 bg-black z-[1001] transition-opacity duration-300 ease-in-out
    ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}
  `;

  return (
    <>
      <div
        className={overlayClassName}
        onClick={onClose}
        aria-hidden="true"
      ></div>
      <div className={sheetClassName} role="dialog" aria-modal="true" aria-labelledby="bottomSheetTitle">
        <button
          onClick={onClose}
          className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 px-3 pb-2 pt-1 rounded-full absolute top-4 right-4 text-2xl text-gray-700 dark:text-gray-200"
          aria-label="Close pub information"
        >
          &times;
        </button>
        <div className="p-4 sm:p-6">
          <CustomInfoWindow pub={pub} count={count} />
        </div>
      </div>
    </>
  );
};

const CheapestPintMap = () => {
  const [pubs, setPubs] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.50, -0.1]);
  const [mapZoom, setMapZoom] = useState(13);
  const { isDark } = useTheme(); // Get the current theme
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [filters, setFilters] = useState({
    wetherspoons: 'show',
    amenities: [],
  });
  const [selectedPub, setSelectedPub] = useState(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showBeerMileRoute, setShowBeerMileRoute] = useState(false);
  const [selectedPintFilter, setSelectedPintFilter] = useState(null);

  const toggleBeerMileRoute = () => {
    setShowBeerMileRoute(!showBeerMileRoute);
  };

  const mapRef = useRef(null);

  const getIcon = (pub, isSelected = false) => {
    const price = pub.price;
    const guinnessInStock = pub.guinnessRating;
    const timestamp = pub.timestamp;

    // Check if Guinness is in stock and the report is less than 24 hours old
    if (guinnessInStock && timestamp) {
      const reportTime = new Date(timestamp).getTime();
      const currentTime = new Date().getTime();
      const hoursDiff = (currentTime - reportTime) / (1000 * 60 * 60);

      if (hoursDiff <= 24) {
        return L.icon({
          iconUrl: blackSvgIcon,
          iconSize: [48, 56],
          iconAnchor: [24, 56],
          popupAnchor: [0, -56],
        });
      }
    }

    if (isSelected) {
      return L.icon({
        iconUrl: selectedPubSvgIcon,
        iconSize: [48, 56],
        iconAnchor: [24, 56],
        popupAnchor: [0, -56],
      });
    }

    let iconUrl;
    if (price <= 4.5) {
      iconUrl = greenSvgIcon;
    } else if (price <= 6) {
      iconUrl = orangeSvgIcon;
    } else {
      iconUrl = redSvgIcon;
    }

    return L.icon({
      iconUrl,
      iconSize: [48, 56],
      iconAnchor: [24, 56],
      popupAnchor: [0, -56],
    });
  };

  const shouldHaveStar = (pints) => {
    const pintCounts = pints.reduce((acc, pint) => {
      const key = `${pint.beerType}_${pint.price}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    return Object.values(pintCounts).some(count => count >= 3);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    console.log('New filters (in CheapestPintMap):', newFilters); // Debugging
  };

  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp);
    const pubsRef = ref(database, "beerPrices");

    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      const pubData = data ? Object.values(data) : [];
      const pubMap = {};

      pubData.forEach((pub) => {
        if (!pubMap[pub.pub]) {
          pubMap[pub.pub] = {
            ...pub,
            pints: [],
            id: pub.pub.replace(/\s+/g, '-').toLowerCase(),
          };
          addToRecentPubs({
            pub: pub.pub,
            pubName: pub.pub,
            roadName: pub.roadName,
            town: pub.town,
            lat: pub.lat,
            lng: pub.lng
          });
        }
        pubMap[pub.pub].pints.push({
          beerType: pub.beerType,
          price: pub.price,
          timestamp: pub.timestamp,
          guinnessRating: pub.guinnessRating,
        });
      });

      const getUserLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setUserLocation([position.coords.latitude, position.coords.longitude]);
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      };

      getUserLocation();

      const pubsArray = Object.values(pubMap).map(pub => {
        const cheapestPint = pub.pints.reduce((min, pint) => pint.price < min.price ? pint : min, pub.pints[0] || {});
        return {
          ...pub,
          price: cheapestPint.price,
          beerType: cheapestPint.beerType,
          timestamp: cheapestPint.timestamp,
          guinnessRating: cheapestPint.guinnessRating,
          hasStar: shouldHaveStar(pub.pints),
        };
      });

      setPubs(pubsArray);
    };

    onValue(pubsRef, onDataChange);

    return () => {
      off(pubsRef, onDataChange);
    };
  }, []);

  const BeerMileRoute = () => {
    const map = useMap();

    useEffect(() => {
      if (showBeerMileRoute) {
        const beerMilePubs = [
          { name: "Faltering Fullback", lat: 51.5587, lng: -0.1086 },
          { name: "The Tollington", lat: 51.5595, lng: -0.1095 },
          { name: "The Gunners", lat: 51.5605, lng: -0.1085 },
          { name: "Twelve Pins", lat: 51.5615, lng: -0.1075 },
        ];

        const markers = beerMilePubs.map(pub =>
          L.marker([pub.lat, pub.lng]).bindPopup(pub.name)
        );

        const group = L.featureGroup(markers).addTo(map);
        map.fitBounds(group.getBounds());

        return () => {
          group.remove();
        };
      }
    }, [showBeerMileRoute, map]);

    return null;
  };

  const isValidCoordinate = (lat, lng) => {
    return typeof lat === 'number' && !isNaN(lat) &&
      typeof lng === 'number' && !isNaN(lng) &&
      lat >= -90 && lat <= 90 &&
      lng >= -180 && lng <= 180;
  };

  const filteredPubs = pubs.filter(pub => {
    // First check for valid coordinates
    if (!isValidCoordinate(pub.lat, pub.lng)) return false;

    // Check Wetherspoons filter
    const isWetherspoons = pub.pub.toLowerCase().includes('wetherspoon');
    if (isWetherspoons && filters.wetherspoons === 'hide') {
      return false;
    }

    // Check Guinness in stock filter
    if (filters.amenities.includes('guinness')) {
      // Check if any pint of Guinness was reported within the last 24 hours
      const hasRecentGuinness = pub.pints.some(pint => {
        if (pint.beerType.toLowerCase() === 'guinness' && pint.timestamp) {
          const reportTime = new Date(pint.timestamp).getTime();
          const currentTime = new Date().getTime();
          const hoursDiff = (currentTime - reportTime) / (1000 * 60 * 60);
          return hoursDiff <= 24;
        }
        return false;
      });

      if (!hasRecentGuinness) return false;
    }

    // Check other amenities filters
    if (filters.amenities.includes('beer-garden') && !pub.beerGarden) {
      return false;
    }
    if (filters.amenities.includes('live-music') && !pub.liveMusic) {
      return false;
    }
    if (filters.amenities.includes('live-sport') && !pub.liveSports) {
      return false;
    }

    // Check pint type filter
    if (selectedPintFilter) {
      // Find the most recent price for the selected beer type
      const matchingPints = pub.pints.filter(pint =>
        pint.beerType.toLowerCase() === selectedPintFilter.toLowerCase()
      );

      if (matchingPints.length === 0) {
        return false;
      }

      // Update pub's displayed price to the most recent price of the selected beer
      const mostRecentPint = matchingPints.reduce((latest, current) =>
        new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest
      );
      pub.price = mostRecentPint.price;
    } else {
      // When no filter is selected, use the cheapest price from all pints
      pub.price = Math.min(...pub.pints.map(pint => pint.price));
    }

    return true;
  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = useCallback(async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchInput)}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
    );
    const data = await response.json();
    const results = data.features.map(feature => ({
      placeName: feature.place_name,
      lat: feature.geometry.coordinates[1],
      lng: feature.geometry.coordinates[0]
    }));
    setSearchResults(results);

    if (results.length > 0) {
      setMapCenter([results[0].lat, results[0].lng]);
      setMapZoom(14);
      if (mapRef.current) {
        mapRef.current.setView([results[0].lat, results[0].lng], 14);
      }
    }
  }, [searchInput]);

  const MapContent = ({ setMapCenter, setMapZoom }) => {
    const map = useMap();

    useEffect(() => {
      const mapEvents = map.on('moveend', () => {
        setMapCenter(map.getCenter());
        setMapZoom(map.getZoom());
      });

      return () => {
        map.off('moveend', mapEvents);
      };
    }, [map, setMapCenter, setMapZoom]);

    return null;
  };

  return (
    <div className='relative min-h-screen'>
      <div className="relative z-10 py-2">
        <div className="w-full max-w-4xl mx-auto">
          <PintFilterChips
            pubs={pubs}
            onFilterChange={(beerType) => setSelectedPintFilter(beerType)}
          />
        </div>
      </div>
      <div className="map-container">
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: '100vh', width: '100%' }}
          ref={mapRef}
          aria-label="Map of pubs with cheapest pints"
        >
          <LocationButton />
          <BeerMileRoute />
          <MapControlSheet onFilterChange={handleFilterChange} setSelectedPintFilter={setSelectedPintFilter} />

          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/${isDark ? 'navigation-night-v1' : 'streets-v12'}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}&theme=monochrome`}
            tileSize={512}
            zoomOffset={-1}
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            theme="monochrome"
          />
          <MapContent setMapCenter={setMapCenter} setMapZoom={setMapZoom} />
          {userLocation && isValidCoordinate(userLocation[0], userLocation[1]) && (
            <Marker
              position={userLocation}
              icon={L.icon({
                iconUrl: blueDotSvgIcon,
                iconSize: [48, 56],
                iconAnchor: [24, 56],
                popupAnchor: [0, -56],
              })}
              aria-label="Your location"
            />
          )}
          <MarkerClusterGroup
            disableClusteringAtZoom={14}
            maxClusterRadius={40}
            chunkedLoading
          >
            {filteredPubs.map((pub, index) => {
              const count = pub.pints.length;
              return (
                <Marker
                  key={index}
                  position={[pub.lat, pub.lng]}
                  icon={getIcon(
                    pub,
                    selectedPub && selectedPub.pub.id === pub.id
                  )}
                  eventHandlers={{
                    click: () => {
                      setSelectedPub({ pub, count });
                      setIsBottomSheetOpen(true);
                      setSelectedMarker(pub.pub);
                    },
                  }}
                  aria-label={`${pub.pub} - Cheapest pint: £${pub.price}`}
                />
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
      {selectedPub && (
        <BottomSheet
          pub={selectedPub.pub}
          count={selectedPub.count}
          isOpen={isBottomSheetOpen}
          onClose={() => {
            setIsBottomSheetOpen(false);
            setSelectedPub(null);
            setSelectedMarker(null);
          }}
        />
      )}
    </div>
  );
};

export default CheapestPintMap;
